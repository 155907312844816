'use client';

import type { NextPage } from 'next';
import Head from 'next/head';
import { useEffect } from 'react';
import { PubSub, EVENTS, LANGUAGES, COUNTRIES, API_ENVS } from '@rc-d2c/context-manager';
import { RcButton } from '@rc-d2c/shared-ui/RcButton';
import { RcSelect } from '@rc-d2c/shared-ui/RcSelect';
import AddToCartCard from '../components/AddToCartCard';
import styles from '../styles/common.module.scss';
import { useLocalStorage, useIsClient } from 'usehooks-ts';
import { useStoreCountry } from '../helpers/store';
import { getCurrentEnv } from '../helpers/getCurrentEnv';

const defaultLanguageForStoreMapping: Record<COUNTRIES, LANGUAGES> = {
  [COUNTRIES.FR]: LANGUAGES.frFR,
  [COUNTRIES.NL]: LANGUAGES.nlNL,
  [COUNTRIES.DE]: LANGUAGES.deDE,
  [COUNTRIES.SE]: LANGUAGES.svSE,
  [COUNTRIES.TR]: LANGUAGES.trTR,
  [COUNTRIES.US]: LANGUAGES.enUS,
  [COUNTRIES.GB]: LANGUAGES.enGB,
  // @deprecated [6d5f59e4-4162-4286-a6ae-788520cb127b]
  [COUNTRIES.UK]: LANGUAGES.enGB,
  // deprecated END
  [COUNTRIES.IT]: LANGUAGES.itIT,
};

const switchLanguageSelectOptions = Object.keys(LANGUAGES).map((key) => ({
  value: LANGUAGES[key as keyof typeof LANGUAGES],
  content: key,
}));

const renaissanceCountries = [COUNTRIES.NL, COUNTRIES.IT];

const storeSelectOptions =
  process.env.NEXT_PUBLIC_LEGACY_APIS === 'true'
    ? Object.keys(COUNTRIES)
        .filter((country) => !renaissanceCountries.includes(country as COUNTRIES))
        .map((key) => ({
          value: COUNTRIES[key as keyof typeof COUNTRIES],
          content: key,
        }))
    : renaissanceCountries.map((item) => ({
        value: item,
        content: item,
      }));

const productsByCountry: Partial<
  Record<
    COUNTRIES,
    {
      title: string;
      payload: {
        mainItem: string;
        skuId: string;
        quantity: number;
        isSubscriptionChecked: boolean;
        periodTypeId?: string;
      };
    }[]
  >
> = {
  [COUNTRIES.NL]: [
    {
      title: 'British Shorthair Kitten',
      payload: {
        mainItem: '2566',
        skuId: '8a7081d4818c62cc0181946549510575',
        quantity: 1,
        isSubscriptionChecked: false,
      },
    },
    {
      title: 'Main Coon Adult',
      payload: {
        mainItem: '2550',
        skuId: '8a7081d4818c62cc018194650d2803fd',
        quantity: 3,
        isSubscriptionChecked: true,
        periodTypeId: '61708',
      },
    },
  ],
  [COUNTRIES.FR]: [
    {
      title: 'Giant Junior pour Chiot',
      payload: {
        mainItem: '3031',
        skuId: '2c91808576903fd801769046162701f8',
        quantity: 1,
        isSubscriptionChecked: false,
      },
    },
    {
      title: 'Maxi Joint Care',
      payload: {
        mainItem: '2390',
        skuId: '2c91808576903fd801769045e8a1015a',
        quantity: 1,
        isSubscriptionChecked: true,
        periodTypeId: '61711',
      },
    },
  ],
  [COUNTRIES.DE]: [],
  [COUNTRIES.GB]: [],
  [COUNTRIES.IT]: [],
  [COUNTRIES.SE]: [
    {
      title: 'Maine Coon Kitten',
      payload: {
        mainItem: '2558',
        skuId: '8a7081137bce7f91017be202ee61023a',
        quantity: 1,
        isSubscriptionChecked: false,
      },
    },
    {
      title: 'Ragdoll Adult',
      payload: {
        mainItem: '2515',
        skuId: '8a7081137bce7f91017be202d59a013e',
        quantity: 1,
        isSubscriptionChecked: true,
        periodTypeId: '61711',
      },
    },
  ],
  [COUNTRIES.TR]: [],
  [COUNTRIES.UK]: [],
  [COUNTRIES.US]: [],
};

const Home: NextPage = () => {
  const { storeCountry, setStoreCountry } = useStoreCountry();
  const [language, setLanguage] = useLocalStorage<LANGUAGES>('app-shell-language', LANGUAGES.frFR);
  const isClient = useIsClient();
  const submitLanguage = () => {
    PubSub.publish(EVENTS.SET_GLOBAL_SETTINGS_CONFIG, {
      country: storeCountry,
      language,
      withLegacyApis: process.env.NEXT_PUBLIC_LEGACY_APIS === 'true',
      apiEnv: API_ENVS.STG,
      routes: {
        HOMEPAGE: '/',
        CONTACT_SUPPORT: '/',
      },
    });

    window.location.reload();
  };

  const submitStore = () => {
    const defaultStoreLanguage = defaultLanguageForStoreMapping[storeCountry];
    setLanguage(defaultStoreLanguage);
    PubSub.publish(EVENTS.SET_GLOBAL_SETTINGS_CONFIG, {
      country: storeCountry,
      apiEnv: getCurrentEnv(),
      language: defaultStoreLanguage,
      withLegacyApis: process.env.NEXT_PUBLIC_LEGACY_APIS === 'true',
      routes: {
        HOMEPAGE: '/',
        CONTACT_SUPPORT: '/',
      },
    });

    window.location.reload();
  };

  return (
    <div className={styles.container}>
      <Head>
        <title>Shell next 13</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main className={styles.main}>
        <div className={styles.blockWrapper}>
          <h3 className={styles.blockWrapperTitle}>Switch Store</h3>
          <RcSelect
            name="store"
            options={storeSelectOptions}
            onChange={({ target: { value } }) => setStoreCountry(value as COUNTRIES)}
            value={storeCountry}
            label="Store"
            translations={{
              scrollDownAria: 'Scroll to down',
              scrollUpAria: 'Scroll to up',
            }}
          />
          <RcButton onClick={submitStore} label={'Submit'} />
        </div>
        <div className={styles.blockWrapper}>
          <h3 className={styles.blockWrapperTitle}>Switch Language</h3>
          <RcSelect
            name="language"
            options={switchLanguageSelectOptions}
            onChange={({ target: { value } }) => setLanguage(value as LANGUAGES)}
            value={language}
            label="language"
            translations={{
              scrollDownAria: 'Scroll to down',
              scrollUpAria: 'Scroll to up',
            }}
          />
          <RcButton onClick={submitLanguage} label={'Submit'} />
        </div>
        {isClient && (
          <div>
            <div className={styles.blockWrapper}>
              <h3>{storeCountry.toUpperCase()} products</h3>
              <div className={styles.cardContainer}>
                {productsByCountry[storeCountry]?.map((product) => (
                  <AddToCartCard key={product.title} title={product.title} payload={product.payload} />
                ))}
              </div>
              <div className={styles.cardContainer}>
                <AddToCartCard title="Add your own product" />
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default Home;
